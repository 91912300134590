<template>
    <div class="d-grid gap-2">
        <template v-if="opt.type == 'login'">
            <button
                v-on:click="loginWithFacebook"
                class="btn btn-fb"
                type="button"
                :disabled="loading"
            >
                <i class="fab fa-facebook me-2"></i>Login dengan Facebook
            </button>
            <button
                v-on:click="loginWithGoogle"
                class="btn btn-danger"
                type="button"
                :disabled="loading"
            >
                <i class="fab fa-google me-2"></i>Login dengan Google
            </button>
        </template>
        <template v-else>
            <button
                v-on:click="registerWithFacebook"
                class="btn btn-fb"
                type="button"
                :disabled="loading"
            >
                <i class="fab fa-facebook me-2"></i>Daftar dengan Facebook
            </button>
            <button
                v-on:click="registerWithGoogle"
                class="btn btn-danger"
                type="button"
                :disabled="loading"
            >
                <i class="fab fa-google me-2"></i>Daftar dengan Google
            </button>
        </template>
    </div>
</template>
<script>
import {
    getAuth,
    signInWithRedirect,
    getRedirectResult,
    FacebookAuthProvider,
    GoogleAuthProvider,
} from 'firebase/auth';
import { getError } from '@/utils/helpers';
import AuthService from '@/services/AuthService';

export default {
    name: 'login_signup_social',
    data() {
        return {
            opt: this.options,
            notif: this.notif_alert,
            loading: this.load,
        };
    },
    props: ['options', 'notif_alert', 'load'],
    model: {
        prop: 'load',
        event: 'change',
    },
    mounted() {
         var auth = getAuth();
         getRedirectResult(auth)
            .then((result) => {
                if(this.opt.type == "login") {
                    this.onLogin(result)
                } else {
                    this.onRegister(result)
                }
            }).catch((error) => {
                // Handle Errors here.
                console.log(error.message);
          });
    },
    methods: {
        loginWithGoogle() {
            if(this.opt.isCheckbox == false) {
                return;
            }
            this.loading = true;
            this.$emit('login_on', this.loading);

            var provider = new GoogleAuthProvider();
            var auth = getAuth();
            signInWithRedirect(auth, provider);
        },
        loginWithFacebook() {
            if(this.opt.isCheckbox == false) {
                return;
            }
            this.loading = true;
            this.$emit('login_on', this.loading);

            var provider = new FacebookAuthProvider();
            var auth = getAuth();
            signInWithRedirect(auth, provider);
        },
        async onLogin(result) {
           

            if (result.user.providerData) {

                this.loading = true;
                this.$emit('login_on', this.loading);

                const payload = {
                    email: result.user.providerData[0].email,
                    device_name: 'web',
                };

                await AuthService.loginSosmed(payload)
                    .then((response) => this.onResponseLogin(response))
                    .catch((error) => getError(error));
            }
        },
        async onResponseLogin(response) {
            if (response.data.result == 'success') {
                const token = response.data.token.split('|')[1];
                this.$store.dispatch('auth/setToken', { value: token });
                this.$store.dispatch('auth/setEmail', { value: this.email });

                await this.$store.dispatch('auth/getAuthUser');
                this.$store.dispatch('auth/setMenuActive', {
                    value: 'dashboard',
                });
                this.$store.dispatch('auth/setMessage', { value: '' });
                this.$router.push('/dashboard');
            } else {
                this.notif.alertType = 'alert-danger';
                this.notif.alert = 'block';
                this.notif.alertMessage = response.data.message;
            }

            this.loading = false;
            this.$emit('login_on', this.loading);

        },
        registerWithGoogle() {
            if(this.opt.isCheckbox == false) {
                return;
            }
            this.loading = true;
            this.$emit('register_on', this.loading);

            var provider = new GoogleAuthProvider();
            var auth = getAuth();
            signInWithRedirect(auth, provider);
        },
        registerWithFacebook() {
            if(this.opt.isCheckbox == false) {
                return;
            }
            this.loading = true;
            this.$emit('register_on', this.loading);

            var provider = new FacebookAuthProvider();
            var auth = getAuth();
            signInWithRedirect(auth, provider);
        },
        async onRegister(result) {
            if (result.user.providerData) {
                this.loading = true;
                this.$emit('register_on', this.loading);

                const payload = {
                    name: result.user.providerData[0].displayName,
                    email: result.user.providerData[0].email,
                    type: 'dokter',
                    provider: result.user.providerData[0].providerId,
                    provider_id: result.user.providerData[0].uid,
                    provider_token: result.accessToken,
                    provider_avatar: result.user.providerData[0].photoURL,
                };

                await AuthService.registerSosmed(payload)
                    .then((response) => this.onResponseRegister(response))
                    .catch((error) => getError(error));
            }
        },
        onResponseRegister(response) {
            if (response.data.result == 'error') {
                this.notif.alert = 'block';
                this.notif.alertMessage = response.data.message;
            } else {
                // this.$store.dispatch("auth/setMessage",{value:response.data.message})
                // this.$router.push({name:"login"})
                this.onLoginAfterRegis(response.data.user.email);
            }

            this.loading = false;
            this.$emit('register_on', this.loading);
        },
        async onLoginAfterRegis(email) {
            const payload = {
                email: email,
                device_name: 'web',
            };

            await AuthService.loginSosmed(payload)
                .then((response) => this.onResponseLogin(response))
                .catch((error) => getError(error));
        },
    },
    watch: {
        load: function (val) {
            this.loading = val;
        },
    },
};
</script>
